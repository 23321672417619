import FeedbackSection from "./feedback/FeedbackSection";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import HowItWorks from "./how-it-works/How-it-works";
import Header from "./NavBar";
import Services from "./Services/Service";
import SliderSection from "./hero_section/SliderSection";
import WhatToBuySection from "./what-buy/what-buy";
import NewsletterSignupSection from "./Newslatter/newsletter_signup";
import FAQ from "./FAQ/faq";
import AnnouncementSection from "./Alerte/announcement";
// import { useEffect } from "react";

const Homepage = ({ user }) => {
  const { t } = useTranslation();

//   useEffect(() => {
//     // Dynamically load the chatbot script
//     const script = document.createElement("script");
//     script.src = "https://widget.cxgenie.ai/widget.js";
//     script.async = true;
//     script.setAttribute("data-aid", "4506fd38-76f9-4f14-9536-bbd43c76d75c");
//     script.setAttribute("data-lang", "ar");
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

  return (
    <div>
      <div className="container py-20">
        <Helmet>
          <title>{t("home-screen")} </title>
        </Helmet>
        <div className="hero_area">
          <Header user={user} />
          <AnnouncementSection />
          <SliderSection />
        </div>
        <Services />
        <HowItWorks />
        <FeedbackSection />
        <WhatToBuySection />
        <FAQ />
        <NewsletterSignupSection />
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
